body {
  background-color: #232841;
  flex-flow: column;
  display: flex;
  overflow: hidden;
}

html {
  color: #fdfffc;
  word-spacing: .25rem;
  scrollbar-color: #fdfffc #232841;
  scrollbar-width: thin;
  font-family: Inter, sans-serif;
  font-size: 10px;
}

h1 {
  font-size: 5rem;
  font-weight: 800;
}

h2 {
  font-size: 4rem;
  font-weight: 750;
}

h3 {
  font-size: 3.25rem;
  font-weight: 500;
}

h4 {
  font-size: 2.5rem;
  font-weight: 500;
}

h5 {
  font-size: 2.25rem;
  font-weight: 400;
}

p {
  font-size: 1.75rem;
  font-weight: 300;
}

a {
  color: #fdfffc;
  outline: none;
  text-decoration: none;
}

#header {
  height: 10vh;
  width: 100%;
  box-sizing: border-box;
  flex-flow: row;
  flex: 0 10%;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: .5rem;
  display: flex;
}

#header-logo-box {
  height: auto;
  width: 20%;
  max-height: 2px;
}

#header-logo {
  height: 36px;
  width: 289px;
}

#menu {
  width: 30%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#menu-arrow-box {
  box-sizing: border-box;
  flex: 1;
  order: 1;
  padding: .5rem;
}

#menu-arrow {
  transition-property: fill;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
  position: absolute;
}

#menu-arrow:hover {
  fill: #ff8c61;
}

#menu-content {
  box-sizing: border-box;
  flex: 3;
  order: 2;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  display: flex;
}

.menu-text {
  color: #ff8c61;
  font-size: 1.65rem;
}

#error-container {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 20%;
  display: flex;
}

#message {
  box-sizing: border-box;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 4.5rem 15%;
  display: flex;
}

.button {
  color: #232841;
  width: 10rem;
  text-align: center;
  background-color: #ff8c61;
  border: .2rem solid #ff8c61;
  border-radius: 5rem;
  margin: auto;
  padding: 1rem;
  font-size: medium;
  font-weight: bold;
}

/*# sourceMappingURL=404.a1f937a0.css.map */
