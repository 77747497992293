body{
  display: flex;
  flex-flow: column nowrap;
  background-color: #232841;
  overflow: hidden;
}


html {
  color: #fdfffc;
  font-family: Inter, sans-serif;
  font-size: 10px;
  word-spacing: .25rem;

  scrollbar-color: #fdfffc #232841;
  scrollbar-width: thin;

}

h1{
  font-size: 5rem;
  font-weight: 800;
}

h2{
  font-size: 4rem;
  font-weight: 750;
}

h3{
  font-size: 3.25rem;
  font-weight: 500;
}

h4{
  font-size: 2.5rem;
  font-weight: 500;
}

h5{
  font-size: 2.25rem;
  font-weight: 400;
}

p{
  font-size: 1.75rem;
  font-weight: 300;
}

a{
  outline: none;
  text-decoration: none;
  color: #fdfffc;
}

#header{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 10vh;
  width: 100%;
  flex: 0 10%;
  padding-top: 1.5rem;
  padding-bottom: .5rem;
  box-sizing: border-box
}

#header-logo-box{
  height: auto;
  width: 20%;
  max-height: 2px;
}

#header-logo{
  height: 36px;
  width: 289px;
}

#menu{
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}


#menu-arrow-box{
  order: 1;
  flex: 1 1 0;
  padding: .5rem;
  box-sizing: border-box;
}

#menu-arrow{
  position: absolute;
  transition-property: fill;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
}

#menu-arrow:hover{
  fill: #ff8c61;
}

#menu-content{
  display: flex;
  order: 2;
  flex: 3 1 0;
  align-items: center;
  gap: 3rem;
  justify-content: flex-start;
  box-sizing: border-box;
}

.menu-text{
  font-size: 1.65rem;
  color: #ff8c61;
}

#error-container{
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding: 20%;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

#message{
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  padding: 4.5rem 15%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button{
  color: #232841;
  border: .2rem solid #ff8c61;
  background-color: #ff8c61;
  border-radius: 5rem;
  width: 10rem;
  padding: 1rem;
  margin: auto;
  text-align: center;
  font-weight: bold;
  font-size: medium;
}

/*XL Screens & TVs*/
@media only screen and (min-width: 1901px){

}

/*Small Screens & Laptops*/
@media only screen and (max-width: 1440px){

}

/*iPads & Tablets*/
@media only screen and (max-width: 1024px){

}

/*Mobile*/
@media only screen and (max-width: 480px){

}

